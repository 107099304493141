const eventTypeLists = [
  { name: 'new_room_created', mqtt: true, fcm: true }, // done
  { name: 'room_division_taken', mqtt: true, fcm: true }, // not yet triggered
  { name: 'room_taken', mqtt: true, fcm: true }, // done.
  { name: 'when_system_send_calls_webhook', mqtt: true, fcm: true },
  { name: 'when_system_send_incoming_calls_webhook', mqtt: true, fcm: true },
  { name: 'user_online_status', mqtt: true, fcm: true }, // done. data on mqtt changed became data.data and different payload
  // { name: 'when_customer_send_message', mqtt: true, fcm: true }, // done. this one must be using senderType
  // { name: 'bot_sent_message', mqtt: false, fcm: true }, // done. data on mqtt changed became data.data. this one handlered in senderType bot
  { name: 'system_sent_message', mqtt: true, fcm: true }, // done. data on mqtt changed became data.data
  { name: 'agent_handover_room', mqtt: true, fcm: true }, // done. data on mqtt changed became data.data.extra
  { name: 'agent_take_room', mqtt: true, fcm: true }, // done
  { name: 'send_webhook', mqtt: true, fcm: true }, // done. data on mqtt changed became data.extra payload
  { name: 'add_agent', mqtt: true, fcm: true }, // done. data on mqtt changed
  { name: 'system_auto_assign_room', mqtt: true, fcm: true }, // not yet triggered
  { name: 'remove_agent', mqtt: true, fcm: true }, // done, data on mqtt changed became data.data.extra
  { name: 'resolve_room', mqtt: true, fcm: true }, // done. data on mqtt changed became data.data.extra
  { name: 'read_messages', mqtt: true, fcm: true },
  { name: 'when_customer_submit_form', mqtt: true, fcm: true },
]

const senderTypeLists = [
  { name: 'Models::Contact', mqtt: true, fcm: true },
  { name: 'Models::User', mqtt: true, fcm: true },
  { name: 'message', mqtt: true, fcm: true },
  { name: 'Models::SystemAccount', mqtt: true, fcm: true },
  { name: 'bot', mqtt: true, fcm: true },
  { name: 'agent_notification', mqtt: true, fcm: true },
]

export { eventTypeLists, senderTypeLists }
